import './faqCommon.scss';
import parse from "html-react-parser";

export default function General(props:any) {
  return (
    <div className='faqQue'>
      <ul>
        <li>{parse(props.paragraph)}</li>
      </ul>
    </div>
  );
}
