import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../../Service/Instance";
import { useState, useEffect } from "react";
import "./faq.scss";
import General from "./FAQ-Components/General";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";

export default function Faq() {
  const [questionList, setQuestionList] = useState<any>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [dropdownTitle, setDropDownTitle] = useState<any>("");
  const [questions, setQuestions] = useState<any>([]);
  const [activeId, setActiveId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [faqList, setFaqList] = useState<any>([]);

  /**
   * this funtion is setting Updatedquestion list in a state
   */

  const getQuestionList = async () => {
    try {
      setLoading(true);
      const questionResponse = await Axios.get("web/questions");

      setQuestionList(questionResponse.data.data);
      if (queryParams.get("faqId")) {
        const selectedOption = (questionResponse.data.data || []).find(
          (item: any) => {
            return item._id === queryParams.get("faqId");
          }
        );
        setActiveId(queryParams.get("faqId"));
        setQuestions(selectedOption.questions);
        setDropDownTitle(selectedOption.name);
      } else {
        setActiveId(questionResponse.data.data[0]._id);
        setQuestions(questionResponse.data.data[0].questions);
        setDropDownTitle(questionResponse.data.data[0].name);
        // setFaqList(questionResponse.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const navigateWithActiveField = (id: any, ques: any, name: any) => {
    setQuestions(ques);
    setActiveId(id);
    setDropDownTitle(name);
    window.scrollTo(0, 0);
    navigate(`/Faq`);
  };

  useEffect(() => {
    getQuestionList();
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="MainCommon">
        <div className="FAQSection">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="FaqHeadSec border-BottomClass">
                  <div className="FaqHeading">
                    <h1>FAQ</h1>
                  </div>
                  <div className="FaqPara">
                    <span>
                      Can't find an answer? Call us at
                      <a href="tel:+207-358-9327"> 207-358-9327</a> or
                      email&nbsp;
                      <a href="mailto:info@waterfrontconcerts.com">
                        info@waterfrontconcerts.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="FaqTableSection">
                <div className="row">
                  <div className="col-md-3">
                    <div className="TableContent tabletVeiwHide">
                      <h5>Table of Contents</h5>
                      <div className="TableContestList">
                        {questionList.map((val: any) => {
                          return (
                            <a
                              key={val._id}
                              className={`${
                                val._id == activeId
                                  ? `feq-title-active`
                                  : `feq-title`
                              } `}
                              onClick={() =>
                                navigateWithActiveField(
                                  val._id,
                                  val.questions,
                                  val.name
                                )
                              }
                            >
                              {val.name}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    {/* Show's on mobile View  */}
                    <div className="WebViewHide">
                      <div className="dropdown">
                        <button
                          className="btn DropBtn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {dropdownTitle}
                        </button>
                        <ul className="dropdown-menu DropUL">
                          {questionList.map((val: any) => {
                            return (
                              <li
                                onClick={() => {
                                  setActiveId(val._id);
                                  setQuestions(val.questions);
                                  setDropDownTitle(val.name);
                                  navigate(`/Faq`);
                                }}
                                key={val._id}
                                className={`${
                                  val._id == activeId
                                    ? `feq-title-active`
                                    : `feq-title`
                                } `}
                              >
                                {val.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    {/* {faqList.map((e: any) => {
                      return (
                        <>
                          <h3>{e.name}</h3>
                          <div className="AccordionsSec">
                            {e.questions.map((item: any) => {
                              return (
                                <General
                                  key={item._id}
                                  paragraph={item.paragraph}
                                />
                              );
                            })}
                          </div>
                        </>
                      );
                    })} */}

                    <h3>{dropdownTitle}</h3>
                    <div className="AccordionsSec">
                      {questions.length > 0 ? (
                        questions.map((item: any) => {
                          return (
                            <General
                              key={item._id}
                              paragraph={item.paragraph}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
