import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccessibleIcon from "../../Assets/Images/guestIcon1.png";
import ACCOMODATIONICON from "../../Assets/Images/guestIcon2.png";
import CLEARICON from "../../Assets/Images/guestIcon3.png";
import SMAOKEICON from "../../Assets/Images/guestIcon4.png";
import "./guestService.scss";
import Select from "react-select";
import { prePareDropDownData } from "./helper";
import * as Yup from "yup";
import LostAndClaimForm from "./LostAndClaimForm/LostAndClaim";
import { Formik, Form, ErrorMessage } from "formik";
import Axios from "../../Service/Instance";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";

export default function GuestService() {
  const [questionList, setQuestionList] = useState([]);
  const [selectedQuestion, setselectedQuestion] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getQuestionList();
  }, []);

  const navigate = useNavigate();
  /**
   * this funtion is setting Updatedquestion list in a state
   */
  const getQuestionList = async () => {
    try {
      setLoading(true);
      const questionResponse = await Axios.get("web/questions");
      const updatedQuestionList = prePareDropDownData(
        questionResponse.data.data
      );
      setQuestionList(updatedQuestionList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }
  /**
   * prepare array into value and label type object
   * @param {Array} list
   * @returns
   */

  const getQuestionList1 = (selectedValue: any, setFieldValue: any) => {
    setselectedQuestion(selectedValue);
    setQuestionId(selectedValue.value);
    setFieldValue("selectedQuestion", selectedValue.value);
  };

  const handleChangeData = () => {
    navigate(`/Faq?faqId=${questionId}`);
  };

  const navigateWithId = (id: any) => {
    navigate(`/Faq?faqId=${id}`);
  };

  const navigateToFaq = () => {
    navigate(`/Faq`);
  };

  return (
    <>
      <div className="GuestSection MainCommon">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="GuestHeading border-BottomClass">
                <h1>Guest Heading</h1>
              </div>
            </div>
          </div>
          <div className="GuestDetails">
            <div className="row">
              <div className="col-lg-8">
                <div className="QuestionSection boxStyle">
                  <div className="QuestionHeading">
                    <h3>FREQUENTLY ASKED QUESTIONS</h3>
                  </div>

                  <Formik
                    initialValues={{ selectedQuestion: "" }}
                    onSubmit={handleChangeData}
                    validationSchema={Yup.object({
                      selectedQuestion: Yup.string().required(),
                    })}
                  >
                    {({ setFieldValue }) => (
                      <Form className="GuestForm">
                        <div className="SearchIcon">
                          <i className="fas fa-search searchIconSet"></i>
                        </div>

                        <Select
                          options={questionList}
                          className="GuestSearchInput"
                          value={selectedQuestion}
                          onChange={(selectedQuestion) => {
                            getQuestionList1(selectedQuestion, setFieldValue);
                          }}
                          placeholder="Choose FAQ"
                        />
                        <div className="SearchSec">
                          <div className="input-group SetPosition">
                            <button type="submit" className="SeacrhFaqbtn">
                              SEARCH
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <div className=" GoFaqPageBtn">
                    <a onClick={() => navigateToFaq()}>
                      <button type="button" className="btn-primary GoFaqBtn">
                        GO TO FAQ PAGE
                      </button>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <a
                      onClick={() => navigateWithId("676d024a735ba515e75301b5")}
                    >
                      <div className="boxStyle FaqList">
                        <a href="javascript:void(0)">
                          <img src={AccessibleIcon} alt="" />
                          <h3>ACCESSIBLE SERVICES</h3>
                        </a>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6">
                    <a onClick={() => navigateToFaq()}>
                      <div className="boxStyle FaqList">
                        <a href="javascript:void(0)">
                          <img src={ACCOMODATIONICON} alt="" />
                          <h3>ACCOMODATIONS</h3>
                        </a>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6">
                    <a onClick={() => navigateToFaq()}>
                      <div className="boxStyle FaqList">
                        <a href="javascript:void(0)">
                          <img src={CLEARICON} alt="" />
                          <h3>CLEAR BAG</h3>
                        </a>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6">
                    <a
                      onClick={() => navigateWithId("676d02ce735ba515e753020f")}
                    >
                      <div className="boxStyle FaqList">
                        <a href="javascript:void(0)">
                          <img src={SMAOKEICON} alt="" />
                          <h3>SMOKE FREE</h3>
                        </a>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="LostSection boxStyle">
                  <div className="LostHeading">
                    <h3>LOST AND FOUND CLAIM</h3>
                    <p>Lost Something?</p>
                    <p>
                      Please fill out the form below and we&apos;ll contact you
                      let you know if we found your item.
                    </p>
                    <p>
                      Fields marked with <span className="StarColor">*</span>
                      are required.
                    </p>
                  </div>
                  <div className="LostFormSec">
                    <LostAndClaimForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
