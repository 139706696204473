import React from "react";
import GooglePlayIcon from "../../Assets/Images/googlePlayIcon.png";
import HeaderLogo from "../../Assets/Images/HeaderLogo.png";
import AppStoreIcon from "../../Assets/Images/appStoreIcon.png";
import "../../Components/Navbar/navbar.scss";
import "../Footer/footer.scss";
import { NavLink } from "react-router-dom";
export default function Footer() {
  const dateGenerator = () => {
    const d = new Date();
    return d.getFullYear();
  };
  const currentYear = dateGenerator();
  return (
    <>
      <div className="footer--pin">
        <footer>
          <div className="FooterSection">
            <div className="container foot">
              <div className="row">
                <div className="col-md-12">
                  <div className="footerSec">
                    <div className="FooterDetails">
                      <span className="DownloadHeading">
                        DOWNLOAD WATERFRONT CONCERTS APP.
                      </span>
                      <span className="FavHeading">
                        FAVORITE YOUR SHOWS. GET UPDATES.
                      </span>
                      <br className="BRclass" />
                      <span className="AppstoreIcon">
                        <a
                          href="https://apps.apple.com/us/app/waterfront-concerts/id673252714"
                          target="_blank"
                        >
                          <img src={AppStoreIcon} alt="" />
                        </a>
                      </span>
                      <span className="googleAppIcon">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.sephone.WaterfrontConcertsNew"
                          target="_blank"
                        >
                          <img src={GooglePlayIcon} alt="" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bgBlack">
            <div className="container">
              <div className="NavbarSection">
                <p className="navbar-brand">
                  <img className="HeaderLogoIcon" src={HeaderLogo} alt="" />
                </p>
                <ul className="pagelinks">
                  <NavLink className="footer-nav-link" to="/">
                    <li>
                      <a href="/">EVENTS</a>
                    </li>
                  </NavLink>
                  <NavLink className="footer-nav-link" to="/Venue">
                    <li>
                      <a href="/venues">VENUES</a>
                    </li>
                  </NavLink>
                  <NavLink className="footer-nav-link" to="/GuestService">
                    <li>
                      <a href="/guest">GUEST SERVICES</a>
                    </li>
                  </NavLink>
                  <NavLink className="footer-nav-link" to="/Package">
                    <li>
                      <a href="/Package">PACKAGES</a>
                    </li>
                  </NavLink>
                  <NavLink className="footer-nav-link" to="../contact">
                    <li>
                      <a href="../contact">CONTACT</a>
                    </li>
                  </NavLink>
                  <NavLink
                    className="footer-nav-link"
                    to={`/Faq`}
                  >
                    <li>
                      <a>FAQ</a>
                    </li>
                  </NavLink>
                </ul>
                <div className="SocialIcons">
                  <a
                    href="https://www.facebook.com/wfconcertsME"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://twitter.com/wfconcerts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/user/wfconcerts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/wfconcerts/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.snapchat.com/add/wfconcerts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-snapchat"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="LastLine">
            <p>
              {`© ${currentYear} Waterfront Concerts. All Rights Reserved. Designed
              by`}

              <a href="https://www.autumnlane.co/" target="_blank">
                Autumn Lane.
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
